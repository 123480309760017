import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  NgModule,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import { sortBy } from "sort-by-typescript";
import { SnackBarService } from "src/app/snack-bar.service";
import { MatIconModule } from "@angular/material/icon";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
})
@Component({
  selector: "app-applied-candidates-list",
  templateUrl: "./applied-candidates-list.component.html",
  styleUrls: ["./applied-candidates-list.component.css"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class AppliedCandidatesListComponent implements OnInit {
  jobPostUuid: any;
  companyUuid: any;
  content: any;
  applications: any;
  fileUrl: string;
  currentApplication: any;
  sorting: string = "desc";
  fullname: string;
  title: string;
  assignFlag: Boolean;
  candidateJobPostId: number;
  candidatesUuid: string;
  reviewData: any;
  reviewByJobData: any;
  reviewFlag: Boolean;
  reviewId: number;

  duration = 3000;
  horizontalPosition = "center";
  verticalPotition = "top";
  panelClass = "success-dialog";

  rating: number = 0;
  starCount: number = 5;
  ratingArr: boolean[] = []; // true = solid star; false = empty star

  postReviewApplyForm: FormGroup;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public router: Router,
    private snackBar: SnackBarService,
    public formBuilder: FormBuilder
  ) {
    this.ratingArr = Array(this.starCount).fill(false);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.jobPostUuid = params.uuid;
      this.companyUuid = params.company_uuid;

      this.authService
        .getJobDetailsByuuid(this.jobPostUuid)
        .subscribe((data) => {
          this.title = data.title;
        });

      this.authService
        .getAppliedCandidates(this.companyUuid, this.jobPostUuid, this.sorting)
        .subscribe((data) => {
          this.applications = data;
          this.content = data.content;
          this.searchForSelectedFlag(data.content);
        });

      this.authService
        .getCandidateReviewByJobPost(this.jobPostUuid)
        .subscribe((data) => {
          this.reviewByJobData = data;
          this.reviewId = data.id;
          this.searchForReview(data);
        });
    });

    this.postReviewApplyForm = this.formBuilder.group({
      cooperationDuration: new FormControl("", [Validators.required]),
      notes: new FormControl("", [Validators.required]),
    });
  }

  get formControls() {
    return this.postReviewApplyForm.controls;
  }

  candidateProfile(uuid) {
    this.router.navigate(["candidate/profile", uuid]);
  }

  jobDetails() {
    this.router.navigate(["job-details", this.jobPostUuid]);
  }

  jobListing() {
    this.router.navigate(["/employers/job-listing/all"]);
  }

  openModal(applicationData) {
    this.currentApplication = applicationData;
    $("#applyModal").modal("show");
  }

  openReviewModal(applicationData) {
    this.currentApplication = applicationData;
    this.candidatesUuid = applicationData.candidateJobPostInfo.candidateUuid;
    $("#reviewModal").modal("show");
  }

  openGetReviewModal(applicationData) {
    this.currentApplication = applicationData;
    $("#getReviewModal").modal("show");
  }

  closeGetReviewModal() {
    $("#getReviewModal").modal("hide");
  }

  closeReviewModal() {
    $("#reviewModal").modal("hide");
  }

  downloadCV(data) {
    const cv = this.authService.downloadCVOfJobApplicationForCompany(
      data.candidateJobPostInfo.jobPostUuid,
      data.candidateJobPostInfo.candidateUuid
    );
    this.fileUrl = cv;
  }

  // for sorting: asc or desc
  onChange(e) {
    this.sorting = e.target.value === "desc" ? "desc" : "asc";

    if (this.sorting == "desc") {
      this.content = this.content.sort(
        sortBy("-candidateJobPostInfo.creationDate")
      );
    } else {
      this.content = this.content.sort(
        sortBy("candidateJobPostInfo.creationDate")
      );
    }
  }

  assignJob(content) {
    this.authService
      .updateCandidateJobPost(content)
      .subscribe((assignedData) => {
        this.snackBar.openSnackBar(
          "Η ανάθεση τηλεργασίας έγινε με επιτυχία",
          this.duration,
          this.verticalPotition,
          this.horizontalPosition,
          this.panelClass
        );
        this.jobListing();
      });
  }

  unassignJob(content) {
    this.authService
      .updateCandidateJobPost(content)
      .subscribe((assignedData) => {
        this.snackBar.openSnackBar(
          "Η ακύρωση ανάθεσης έγινε με επιτυχία",
          this.duration,
          this.verticalPotition,
          this.horizontalPosition,
          this.panelClass
        );
        this.jobListing();
      });
  }

  visibilityCancelButtonCheck(content) {
    if (
      content.selectedFlag === true &&
      this.candidateJobPostId === content.id
    ) {
      return false;
    } else {
      return true;
    }
  }

  visibilityReviewButton(content) {
    if (
      content.selectedFlag === true &&
      this.candidateJobPostId === content.id &&
      (this.reviewFlag === true || this.reviewFlag === undefined)
    ) {
      return false;
    } else {
      return true;
    }
  }

  visibilityReviewInfo(content) {
    if (
      content.selectedFlag === true &&
      this.candidateJobPostId === content.id &&
      this.reviewFlag === false
    ) {
      return false;
    } else {
      return true;
    }
  }

  searchForSelectedFlag(content) {
    for (let i = 0; i < content.length; i++) {
      if (content[i].candidateJobPostInfo.selectedFlag === true) {
        this.candidateJobPostId = content[i].candidateJobPostInfo.id;
        this.assignFlag = true;
      }
    }
  }

  searchForReview(review) {
    if (review.id !== null && review.id !== undefined) {
      this.reviewFlag = false;
    } else {
      this.reviewFlag = true;
    }
  }

  postReview() {
    let reviewBody = {
      jobPostUuid: this.jobPostUuid,
      notes: this.postReviewApplyForm.value.notes,
      rating: this.rating,
      cooperationDuration: this.postReviewApplyForm.value.cooperationDuration,
    };
    this.authService
      .postCandidateReview(this.candidatesUuid, reviewBody)
      .subscribe((res) => {
        this.closeReviewModal();
        this.jobListing();
        this.snackBar.openSnackBar(
          "Η αξιολόγηση του υποψηφίου καταχωρήθηκε",
          this.duration,
          this.verticalPotition,
          this.horizontalPosition,
          this.panelClass
        );
      });
  }

  deleteReview() {
    this.authService.deleteReview(this.reviewId).subscribe((res) => {
      this.closeGetReviewModal();
      this.jobListing();
      this.snackBar.openSnackBar(
        "Η αξιολόγηση διαγράφηκε",
        this.duration,
        this.verticalPotition,
        this.horizontalPosition,
        this.panelClass
      );
    });
  }

  showStars(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  onRatingChanged(i: number) {
    this.rating = i + 1;
  }
}

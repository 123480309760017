import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../auth.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-administrator-header",
  templateUrl: "./administrator-header.component.html",
  styleUrls: ["./administrator-header.component.css"],
})
export class AdministratorHeaderComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  adminUuid: any;
  adminUsername: string;
  adminRole: string;
  isSigned: boolean;
  epimelitirio: string;
  ngOnInit() {
    this.authService.isAdminAuthenticated$.subscribe((res) => {
      this.epimelitirio = environment.environment;
      this.isSigned = res;
      if (this.isSigned) {
        this.adminUuid = this.authService.getAdminUUid();
        this.authService.getAdminProfile(this.adminUuid).subscribe((res) => {
          this.adminUsername = res.username;
          this.adminRole = res.role;
        });
      }
    });
  }

  signout() {
    this.authService.logoutAdmin();
    this.router.navigate(["/administrator"]);
  }

  candidatesHandling(adminuuid) {
    this.router.navigate(["administrator/cvHandling", adminuuid]);
  }

  createMediator(adminUuid) {
    this.router.navigate(["administrator/createMediators", adminUuid]);
  }
}

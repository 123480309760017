import { Component, OnInit } from "@angular/core";
import { RouterLink, Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/auth.service";
import { Observable } from "rxjs";
import { MetadataService } from "../MetadataService";
import { shareReplay, map } from "rxjs/operators";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-profile",
  templateUrl: "./employer-profile.component.html",
  styleUrls: ["./employer-profile.component.css"],
})
export class ProfileComponent implements OnInit {
  companyData: any;
  loggedInCompanyProfile: boolean;
  metadata$: Observable<any>;
  locations$: Observable<Array<{ id: number; name: string }>>;
  industries$: Observable<Array<{ id: number; name: string }>>;
  jobList: any;
  items: any[];
  pageOfItems: Array<any>;
  employment_types$: Observable<Array<{ id: number; name: string }>>;
  companyuuid: any;

  // for pagination
  currentPage: number = 1;
  pageSize: number = 3;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private metadataService: MetadataService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((obj: { data: any }) => {
      this.route.params.subscribe((params) => {
        this.companyuuid = params.id;
        this.companyData = obj.data;
        //determine is the profile of the company that will be rendered is of the company that is logged in
        this.companyuuid == this.authService.getCompanyId()
          ? (this.loggedInCompanyProfile = true)
          : (this.loggedInCompanyProfile = false);
        if (this.companyData.logo_id) {
          this.companyData.logo = this.authService.getLogo(
            "company",
            "logo",
            this.companyData.id,
            new Date().getTime().toString()
          );
        } else {
          this.companyData.logo = null;
        }
        //get metadata
        this.metadata$ = this.metadataService
          .getMetaData()
          .pipe(shareReplay(1));

        this.industries$ = this.metadata$.pipe(
          map((metadata) =>
            metadata.industries.map((industry) => ({
              id: industry.id,
              text: industry.name,
            }))
          )
        );
        this.locations$ = this.metadata$.pipe(
          map((metadata) =>
            metadata.locations.map((location) => ({
              id: location.id,
              text: location.name,
            }))
          )
        );
        this.employment_types$ = this.metadata$.pipe(
          map((metadata) =>
            metadata.employment_types.map((employment_type) => ({
              id: employment_type.id,
              text: employment_type.name,
            }))
          )
        );

        this.getPublishedJobs();
      });
    });
  }

  getPublishedJobs() {
    // paginations starts from 0
    this.currentPage = this.currentPage - 1;
    this.authService
      .getCompanyPublishedJobs(
        this.companyuuid,
        this.currentPage,
        this.pageSize
      )
      .subscribe((jobList: any) => {
        this.jobList = jobList;
        this.items = jobList.content;
        this.getData();
      });
  }

  postJob() {
    this.router.navigate(["post-a-job"]);
  }

  //compine two observables companyData from resolver and metadata
  getData() {
    this.locations$.subscribe((locations: any[]) => {
      this.items.forEach((item) => {
        let x = locations.find((locItem) => locItem.id === item.location_id);

        if (x) {
          item.location_name = x.text;
        }
      });

      let x = locations.find(
        (locItem) => locItem.id === this.companyData.location_id
      );
      if (x) {
        this.companyData.location_name = x.text;
      }
    });
    this.employment_types$.subscribe((employment_types: any[]) => {
      this.items.forEach((item) => {
        let x = employment_types.find(
          (locItem) => locItem.id === item.employmentTypeId
        );

        if (x) {
          item.employment_types_name = x.text;
        } else {
          item.employment_types_name = "Μη διαθέσιμο";
        }
      });
    });

    this.industries$.subscribe((industries: any[]) => {
      let x = industries.find(
        (item) => item.id === this.companyData.industry_id
      );
      if (x) {
        this.companyData.industry_name = x.text;
      } else {
        this.companyData.industry_name = "";
      }
    });
  }

  completeProfile() {
    this.router.navigate(["employers/complete-profile"]);
  }

  jobDetails(uuid: any) {
    this.router.navigate(["job-details", uuid]);
  }

  employerJobListing() {
    this.router.navigate(["/employers/job-listing", "published"]);
  }

  //for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;
    this.getPublishedJobs();
  }
}

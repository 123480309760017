import { Component, OnInit, Input } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-alert-message",
  templateUrl: "./alert-message.component.html",
  styleUrls: ["./alert-message.component.css"],
})
export class AlertMessageComponent implements OnInit {
  constructor() {}

  @Input() alertOption: number;

  // alert types warning, error
  alertType: string;
  message: string;
  epimelitirio: string;

  ngOnInit() {
    this.epimelitirio = environment.chamber;
    switch (this.alertOption) {
      case 1:
        this.message =
          "Το προφίλ σας δεν έχει ενεργοποιηθεί ακόμα. Προσωρινά δεν θα έχετε τη δυνατότητα να κάνετε αίτηση για θέση τηλεργασίας.";
        this.alertType = "warning";
        return;
      case 2:
        this.message =
          "Το προφίλ σας έχει απενεργοποιηθεί δεν έχετε δυνατότητα αίτησης για θέσης τηλεργασίας. Για περισσότερες πληροφορίες επικοινωνήστε με το " +
          this.epimelitirio +
          ".";
        this.alertType = "error";
        return;
      case 3:
        this.message =
          "Για να έχετε τη δυνατότητα ανάρτησης θέσης τηλεργασίας θα πρέπει να συμπληρώσετε τα πεδία κλάδος και τοποθεσία.";
        this.alertType = "warning";
        return;
      case 4:
        this.message = `Το προφίλ σας δεν έχει ενεργοποιηθεί ακόμα. Μπορείτε να δημιουργήσετε θέσεις Τηλεργασίας αλλά μέχρι να ενεργοποιήθει το προφίλ σας δε
                        θα εμφανίζονται στους υποψήφιους.`;
        this.alertType = "warning";
        return;
      case 5:
        this.message =
          `Το προφίλ σας έχει απενεργοποιηθεί. Μπορείτε να δημιουργήσετε θέσεις Τηλεργασίας αλλά μέχρι να ενεργοποιήθει το προφίλ σας δε
                        θα εμφανίζονται στους υποψήφιους. Για περισσότερες πληροφορίες επικοινωνήστε με το ` +
          this.epimelitirio +
          `.`;
        this.alertType = "error";
        return;
      case 6:
        this.message =
          `Η ανάρτηση σας έχει απερνεργοποιηθεί. Για περισσότερες πληροφορίες επικοινωνήστε με το ` +
          this.epimelitirio +
          `.`;
        this.alertType = "error";
        return;
      case 7:
        this.message =
          `Η ανάρτηση σας δεν έχει ενεργοποιηθεί ακόμα. Για περισσότερες πληροφορίες επικοινωνήστε με το ` +
          this.epimelitirio +
          `.`;
        this.alertType = "warning";
        return;
    }
  }
}

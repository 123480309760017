import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { AuthService } from "../../auth.service";
import { RouterLink, Router } from "@angular/router";
import { MetadataService } from "../../MetadataService";
import { shareReplay, map } from "rxjs/operators";
import { Observable } from "rxjs";
import { NgbDatepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { ConstantsService } from "src/app/ConstantsService";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-post-ajob",
  templateUrl: "./post-a-job.component.html",
  styleUrls: ["./post-a-job.component.css"],
})
export class PostAJobComponent implements OnInit {
  postJobForm: FormGroup;
  metadata$: Observable<any>;
  selectedIndustry: any = null;
  industries$: Observable<Array<{ id: number; text: string; tags: any }>>;
  employment_types$: Observable<Array<{ id: number; text: string }>>;
  experience_levels$: Observable<Array<{ id: number; text: string }>>;
  locations$: Observable<Array<{ id: number; text: string }>>;
  tags: any;
  submitted = false;
  minDate = undefined;
  initEditorDesc: {};
  initEditorBenefitsAndPrerequisites: {};
  message: string;

  //variables for alert message depends on status
  companyActivationStatus = null;
  alertOptionForActivationStatus = null;

  constructor(
    public formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public metadataService: MetadataService,
    public constants: ConstantsService,
    public config: NgbDatepickerConfig,
    public _snackBar: MatSnackBar
  ) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.minDate = {
      year: tomorrow.getFullYear(),
      month: tomorrow.getMonth() + 1,
      day: tomorrow.getDate(),
    };

    this.initEditorDesc = {
      base_url: "/tinymce",
      suffix: ".min",
      entity_encoding: "raw",
      height: 500,
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help",
    };
    this.initEditorBenefitsAndPrerequisites = {
      base_url: "/tinymce",
      suffix: ".min",
      valid_elements: "-ul,-li",
      entity_encoding: "raw",
      setup: function (editor) {
        editor.on("init", function () {
          editor.execCommand("InsertUnorderedList");
          window.scroll(0, 0);
        });
        //  This forces all 'enter' and 'backspace' keys to create an 'ul li' element
        editor.on("keyup", function (e) {
          if (e.keyCode == 13 || e.keyCode == 8) {
            if (
              editor.editorManager.activeEditor.selection
                .getNode()
                .nodeName.toLowerCase() != "li"
            ) {
              editor.execCommand("InsertUnorderedList");
            }
          }
        });
        editor.on("keydown", function (e) {
          if (e.keyCode == 13) {
            if (
              editor.editorManager.activeEditor.selection
                .getNode()
                .nodeName.toLowerCase() != "li"
            ) {
              editor.execCommand("InsertUnorderedList");
            }
          }
        });
      },
      height: 300,
      menubar: false,
      advlist_bullet_styles: "disc",
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar: false,
    };
  }

  ngOnInit() {
    this.getCompanyActivationStatus();
    this.metadata$ = this.metadataService.getMetaData().pipe(shareReplay(1));

    this.industries$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.industries.map((industry) => ({
          id: industry.id,
          text: industry.name,
          tags: industry.tags,
        }))
      )
    );
    this.employment_types$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.employment_types.map((employment_type) => ({
          id: employment_type.id,
          text: employment_type.name,
        }))
      )
    );
    this.experience_levels$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.experience_levels.map((experience_level) => ({
          id: experience_level.id,
          text: experience_level.name,
        }))
      )
    );
    this.locations$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.locations.map((location) => ({
          id: location.id,
          text: location.name,
        }))
      )
    );
    this.postJobForm = this.formBuilder.group({
      title: new FormControl("", [
        Validators.required,
        Validators.maxLength(255),
      ]),
      description: new FormControl("", []),
      prerequisites: new FormControl("", []),
      benefits: new FormControl("", []),
      salary: new FormControl("", [
        Validators.pattern(this.constants.POSITIVE_OR_ZERO_REGEX),
      ]),
      job_url: new FormControl("", [
        Validators.pattern(this.constants.URL_REGEX),
        Validators.maxLength(255),
      ]),
      email: new FormControl("", [Validators.required, Validators.email]),
      industry_id: new FormControl("", []),
      location_id: new FormControl("", [Validators.required]),
      exp_lvl_id: new FormControl("", []),
      employment_type_id: new FormControl("", []),
      tags: new FormControl([], []),
      expiration_date: new FormControl(null, []),
      status: new FormControl(null, []),
    });
  }

  ngAfterViewInit() {
    // import('../../../assets/js/script.js');
  }

  get formControls() {
    return this.postJobForm.controls;
  }

  postAJob(status) {
    this.submitted = true;
    if (this.postJobForm.invalid) {
      window.scroll(100, 500);

      return;
    }

    let expDateJson = this.postJobForm.value.expiration_date;
    if (expDateJson) {
      this.postJobForm.patchValue({
        expiration_date: new Date(
          expDateJson.year,
          expDateJson.month - 1,
          expDateJson.day
        ),
      });
    }
    this.postJobForm.controls["status"].setValue("published");

    //after request message!
    if (status === "published") {
      this.postJobForm.controls["status"].setValue("published");
      this.message = "✓ Η ανάρτηση κοινοποιήθηκε επιτυχώς";
    } else {
      this.postJobForm.controls["status"].setValue("draft");
      this.message = "✓ Η ανάρτηση αποθηκεύτηκε";
    }
    this.authService
      .postAJobForm(this.postJobForm.value, this.authService.getCompanyId())
      .subscribe((jobdetails) => {
        this.router.navigate(["job-details", jobdetails.uuid]);
        this.openSnackBar();
      });
  }

  selectedIndustryHandler(event: any) {
    //update the ui
    //selectedIndustry holds the selected industry id
    this.selectedIndustry = event;
    this.postJobForm.patchValue({
      tags: [],
    });

    this.industries$.subscribe((industries) => {
      this.tags = industries.find(
        (industry) => industry.id === parseInt(this.selectedIndustry, 10)
      ).tags;
    });
  }

  getCompanyActivationStatus() {
    this.authService
      .getCompanyActivationStatus(this.authService.getCompanyId())
      .subscribe((res) => {
        this.companyActivationStatus = res;
        switch (this.companyActivationStatus) {
          case "pending":
            this.alertOptionForActivationStatus = 4;
            break;
          case "disapproved":
            this.alertOptionForActivationStatus = 5;
            break;
          // case 'approved' none alert message
        }
      });
  }

  // pop up message
  openSnackBar() {
    this._snackBar.open(this.message, null, {
      duration: 4000,
    });
  }
}

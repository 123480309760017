import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/auth.service";
import { Observable } from "rxjs";
import { shareReplay, map } from "rxjs/operators";
import { MetadataService } from "src/app/MetadataService";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { DataService } from "src/app/dataService";
import { Location } from "@angular/common";
import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-job-listing",
  templateUrl: "./job-listing.component.html",
  styleUrls: ["./job-listing.component.css"],
})
export class JobListingComponent implements OnInit {
  allJobs: any;
  items: Array<any>;
  totalElements: any;

  metadata$: Observable<any>;
  employment_types$: Observable<Array<{ id: number; name: string }>>;
  locations$: Observable<Array<{ id: number | string; text: string }>>;
  updatedate$: any;
  jobListForm: FormGroup;
  locations: Array<any>;
  isFiltered: boolean = false;
  popUpMessage: string;
  currentJobUuid: any;
  option: any;
  sorting: string = "desc";
  isAssigned: boolean = false;

  // used for the modal
  headerMessage: string;
  bodyMessage: string;
  footerMessage: string;

  // accepted values: all/published/draft/closed
  status: string;

  companyActivationStatus = null;
  alertOptionForActivationStatus = null;

  // for pagination
  currentPage: number = 0;
  pageSize: number = 8;
  firstPage: number = 1;

  constructor(
    public authService: AuthService,
    public metadataService: MetadataService,
    public router: Router,
    public formBuilder: FormBuilder,
    public _snackBar: MatSnackBar,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.status = params.status ? params.status : "published";

      this.jobListForm = this.formBuilder.group({
        jobPosts: new FormControl(this.status, []),
        title: new FormControl("", []),
        locationId: new FormControl("-1", []),
      });

      this.isFiltered = false;
      this.currentPage = this.firstPage;
      this.getList(this.status);
    });
  }

  getMetaData() {
    this.metadata$ = this.metadataService.getMetaData().pipe(shareReplay(1));

    this.employment_types$ = this.metadata$.pipe(
      map((metadata) =>
        metadata.employment_types.map((employment_type) => ({
          id: employment_type.id,
          text: employment_type.name,
        }))
      )
    );
    this.locations$ = this.metadata$.pipe(
      map((metadata) =>
        [{ id: -1, text: "Ολες οι Περιοχές" }].concat(
          metadata.locations.map((location) => ({
            id: location.id,
            text: location.name,
          }))
        )
      )
    );
  }

  //compine alljobs and metadata
  getData() {
    this.locations$.subscribe((locations: any[]) => {
      this.locations = locations;

      this.allJobs.content.forEach((item) => {
        let x = locations.find((locItem) => locItem.id === item.location_id);

        if (x) {
          item.location_name = x.text;
        } else {
          item.location_name = "-";
        }
      });
    });
    this.employment_types$.subscribe((employment_types: any[]) => {
      this.allJobs.content.forEach((item) => {
        let x = employment_types.find(
          (locItem) => locItem.id === item.employmentTypeId
        );

        if (x) {
          item.employment_types_name = x.text;
        } else {
          item.employment_types_name = "-";
        }
      });
    });
  }

  getCompanyActivationStatus() {
    this.authService
      .getCompanyActivationStatus(this.authService.getUUID())
      .subscribe((res) => {
        this.companyActivationStatus = res;
        switch (this.companyActivationStatus) {
          case "pending":
            this.alertOptionForActivationStatus = 4;
            break;
          case "disapproved":
            this.alertOptionForActivationStatus = 5;
            break;
          // case 'approved' none alert message
        }
      });
  }

  jobDetails(uuid: any) {
    this.router.navigate(["job-details", uuid]);
  }

  appliedCandidateList(uuid) {
    this.router.navigate([
      "applied/candidates",
      uuid,
      this.authService.getUUID(),
    ]);
  }

  getList(status) {
    this.status = status;

    // pagination in backend starts at index 0
    this.currentPage = this.currentPage > 0 ? this.currentPage - 1 : 0;

    if (this.status === "all") {
      this.authService
        .getJobListByCompany(
          this.authService.getCompanyId(),
          this.currentPage,
          this.pageSize
        )
        .subscribe((joblist: any) => {
          this.allJobs = joblist;
          this.items = this.allJobs.content;
          this.totalElements = this.allJobs.totalElements;
          this.getMetaData();
          this.getData();
        });
    }

    // draft/ closed / published
    else {
      this.authService
        .getCompanyJobListByStatus(this.status, this.currentPage, this.pageSize)
        .subscribe((joblist: any) => {
          this.allJobs = joblist;
          this.items = this.allJobs.content;
          this.totalElements = this.allJobs.totalElements;
          this.getMetaData();
          this.getData();
        });
    }
  }

  // for the dropdown
  // navigate when status has changed
  dropDownSelectedOption(status) {
    this.router.navigate(["/employers/job-listing", status]);
  }

  getEmployerFilteredList(currentPage?) {
    this.currentPage = currentPage ? currentPage : this.firstPage;
    this.isFiltered = true;

    if (this.jobListForm.invalid) {
      console.log("invalid post a job form");
      return;
    }

    // case locationId is -1
    const locationId = this.jobListForm.get("locationId");
    if (locationId.value == -1) {
      locationId.setValue("");
    }

    // pagination in backend starts at index 0
    this.currentPage = this.currentPage - 1;
    this.authService
      .getJobListByCompanyWithFilters(
        this.jobListForm.value,
        this.currentPage,
        this.pageSize,
        this.jobListForm.controls.jobPosts.value
      )
      .subscribe((joblist: any) => {
        this.allJobs = joblist;
        this.items = this.allJobs.content;
        this.totalElements = this.allJobs.totalElements;

        this.getMetaData();
        this.getData();
      });
  }

  discardfilters() {
    this.isFiltered = false;
    this.currentPage = this.firstPage;

    let currentStatus = this.jobListForm.controls.jobPosts.value;
    this.jobListForm.reset();
    this.jobListForm.markAsUntouched();
    const jobPosts: FormControl = this.jobListForm.get(
      "jobPosts"
    ) as FormControl;
    jobPosts.setValue(currentStatus);
    this.status = currentStatus;

    this.getList(this.status);
  }

  editJob(uuid) {
    this.router.navigate(["employers/edit-post-job", uuid]);
  }

  openModalForIcon(uuid, option) {
    this.currentJobUuid = uuid;
    this.option = option;

    switch (option) {
      case "published":
        this.headerMessage = "Δημοσιεύση Ανάρτησης";
        this.bodyMessage =
          "Είστε σίγουρος ότι θέλετε να δημοσιεύσετε την ανάρτηση; Αν προχωρήσετε σε δημοσίευση η ανάθεση θα ακυρωθεί.";
        this.footerMessage = "Επιβεβαιωση";
        this.popUpMessage = "✓ Η ανάρτηση δημοσιεύτηκε επιτυχώς";
        break;

      case "delete":
        this.headerMessage = "Διαγραφή Ανάρτησης";
        this.bodyMessage =
          "Είστε σίγουρος ότι θέλετε να διαγράψετε την ανάρτηση;";
        this.footerMessage = "Διαγραφη";
        this.popUpMessage = "✓ Η ανάρτηση διαγράφηκε επιτυχώς";
        break;

      case "closed":
        this.headerMessage = "Απόσυρση Ανάρτησης";
        this.bodyMessage =
          "Είστε σίγουρος ότι θέλετε να αποσύρετε την ανάρτηση;";
        this.footerMessage = "Επιβεβαιωση";
        this.popUpMessage = "✓ Η ανάρτηση αποσύρθηκε επιτυχώς";
        break;
    }
    $("#iconModal").modal("show");
  }

  submitModalForIcons(option) {
    this.option = option;
    if (option === "delete") {
      this.authService.deletePostJob(this.currentJobUuid).subscribe((res) => {
        this.currentPage = this.currentPage + 1;

        if (this.isFiltered) {
          this.getEmployerFilteredList(this.currentPage);
        } else {
          this.getList(this.jobListForm.controls.jobPosts.value);
        }

        $("#iconModal").modal("hide");
        this.openSnackBar();
      });
    }

    // case published or closed
    else {
      this.authService
        .updateJobStatus(this.currentJobUuid, this.option)
        .subscribe((res) => {
          this.currentPage = this.currentPage + 1;
          if (this.isFiltered) {
            this.getEmployerFilteredList(this.currentPage);
          } else {
            this.getList(this.jobListForm.controls.jobPosts.value);
          }

          this.authService
            .updateSelectedFlagUnpublish(this.currentJobUuid)
            .subscribe((res) => {});

          $("#iconModal").modal("hide");
          this.openSnackBar();
        });
    }
  }

  //popup message
  openSnackBar() {
    this._snackBar.open(this.popUpMessage, null, {
      duration: 3000,
    });
  }

  // for pagination
  onChangePage(currentPage: number) {
    this.currentPage = currentPage;

    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });

    if (this.isFiltered) {
      this.getEmployerFilteredList(this.currentPage);
    } else {
      this.getList(this.status);
    }
  }
}

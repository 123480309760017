import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-oauth-gemi-for-business",
  templateUrl: "./oauth-gemi-for-business.component.html",
  styleUrls: ["./oauth-gemi-for-business.component.css"],
})
export class OauthGemiForBusinessComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    let code = this.route.snapshot.queryParamMap.get("code");
    let state = this.route.snapshot.queryParamMap.get("state");

    //  if(state === this.cookieService.get('state')){
    this.authService.getCompanyDataFromGemi(code).subscribe(
      (res) => {
        this.authService.signedName = res.name;
        this.authService.isValidCompany = res.isValid;
        // this.authService.signedName = "TEST_CAREER";
        // this.authService.isValidCompany = false;
        if (this.authService.isValidCompany) {
          this.router.navigate([
            "employer/profile",
            this.authService.getCompanyId(),
          ]);
        } else {
          this.router.navigate(["employers/complete-profile"]);
        }
      },
      (error) => {
        console.info("Error during authentication of company");
        console.log(error);
        this.router.navigate(["/authenticationfailed"]);
      }
    );
  }
}

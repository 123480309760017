// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //apiEndPoint: 'https://accijobs-be.chamber.cloud/jobs-0.0.1-SNAPSHOT',
  //apiEndPoint: "https://careerapi.e-a.gr/backend",
  apiEndPoint: "https://careerapi.e-a.gr/jobs-0.0.1-SNAPSHOT",
  //apiEndPoint: "http://localhost:8080/backend",
  production: true,
  // enviroment is used for picking the correct image, so its value must be the image name
  // Για το logo πηγαίνουμε στο φάκελο assets στα images και αποθηκεύουμε το κατάλληλο logo
  environment: "achaea",
  // Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο το
  chamber: "Επιμελητήριο Αχαΐας",
  //Εδώ βάζουμε τιμή που θα χρησιμοποιηθεί για παραμετροποίηση μετά το άρθρο του
  chamber2: "Επιμελητηρίου Αχαΐας",
  chamberEn: "Chamber of Achaea",
  //Those are the contact data change what is needed
  contactData:
    "Στοιχεία επικοινωνίας: Μιχαλακοπούλου 58, Τ.Κ. 26221 ΠΑΤΡΑ, τηλ.: 2610277779 , email: ea@e-a.gr, website: www.e-a.gr/axaia/",
  contactDataEn:
    "Contact Data: Michalakopoulou 58, ZipCode 26221 PATRA, tel: 2610277779, email: ea@e-a.gr, website: www.e-a.gr/axaia/",
  //email Υπεύθυνου για την Προστασία των Δεδομένων Προσωπικού Χαρακτήρα καθε επιμελητηριου
  privacymail: "privacy@e-a.gr",
  googleId:
  "288627487465-nving0g75243t604s9oi92tkbsqsk0q5.apps.googleusercontent.com",
facebookId: "213717490799431",
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
